.recipient-list-wrapper {
    margin-right: -20px;
    margin-left: -20px;
}

.recipient-list-wrapper > div {
    padding: 20px 0 20px 70px;
}

.recipient-list-wrapper > div:nth-of-type(odd) {
    background: #f1f1f1;
}

.recipient-list-wrapper .user,
.recipient-list-wrapper .informacast,
.recipient-list-wrapper .email,
.recipient-list-wrapper .sms {
    position: relative;
    display: block;
}

.recipient-list-wrapper .user::before,
.recipient-list-wrapper .informacast::before,
.recipient-list-wrapper .email::before,
.recipient-list-wrapper .sms::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 28px;
    height: 28px;
    background: url(./assets/users.png) center/cover;
}

.recipient-list-wrapper .email::before {
    background: url(./assets/email.png) center/cover;
}

.recipient-list-wrapper .sms::before {
    background: url(./assets/sms.png) center/cover;
}

.recipient-list-wrapper .informacast::before {
    background: url(./assets/informacast.png) center/cover;
}