.simple-tree-form #info,
.default-tree-form #info {
    padding-right: 25px;
}

.simple-tree-form textarea,
.default-tree-form textarea {
    height: 120px;
}

