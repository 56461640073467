.call-history-recording .title h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.call-history-recording .title h3:first-child {
    margin-top: 38px;
    margin-bottom: 0;
}

.call-history-recording .title h3:last-child {
    margin-top: 0;
    color: rgb(227, 32, 32);
}

.call-history-recording audio {
    display: block;
    margin: 30px auto 0;
}