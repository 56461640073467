.schedule-report-form .cron_builder {
    width: 515px;
    padding: 0;
    border: none;
}

.schedule-report-form .cron-builder-bg {
    background: #1b536a;
}

.schedule-report-form .well.well-small {
    margin-bottom: 20px;
}

.schedule-report-form .span6:last-of-type {
    float: right;
    margin: -80px 100px 10px 0;
}

.report-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.report-data > div > div {
    width: 200px;
    margin-right: 20px;
}

.report-data label {
    display: block;
    transform: scale(1);
}

.email-information {
    display: flex;
    align-items: flex-end;
}

.email-information > div > div {
    width: 400px;
}

.timezone-info {
    position: relative;
    width: 410px;
}

#select-timeZoneType{
    width: 380px;
}

.scheduling-section {
    border: 1px solid #ccc;
    padding: 5px 10px;
    position: relative;
}

.scheduling-section div div span {
    padding: 0;
}

.scheduling-section > fieldset > div > label:first-of-type {
    margin-bottom: 40px;
}

.time-select {
    display: flex;
    align-items: center;
}

.time-select > div {
    width: 100px;
    margin-right: 15px;
}

.schedule-report-form ::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

#saveBtn {
    margin-bottom: 20px;
}