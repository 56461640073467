.warning-banner {
    background: rgb(230, 0, 0);
    color: white;
    margin: 5px;
    padding: 10px;
    font-size: large;
    align-items: center;
    text-align: center;
    box-shadow: 0px 4px 6px 1px #808080eb;
    -webkit-font-smoothing: antialiased;  
    -moz-osx-font-smoothing: grayscale;
}