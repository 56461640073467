.generate-report-form {
    position: relative;
}

.generate-report-form .DayPicker {
    width: 770px !important;
    height: 320px;
    margin: 20px 20px 0 0;
}

.generate-report-form .timezone-field {
    position: relative;
    width: 410px;
    margin: -7px 0 0 20px;
}

.generate-report-form .calendar-radio-buttons {
    position: absolute;
    left: 640px;
    top: 132px;
    z-index: 1;
    width: 130px;
}

.generate-report-form .calendar-radio-buttons span:first-of-type {
    padding: 5px;
}

.generate-report-form .date-view {
    position: relative;
    display: inline-block;
    padding: 12px;
    border: 1px solid #000;
    margin-bottom: 10px;
    min-width: 114px; 
    min-height: 19px;
}

.generate-report-form .date-view:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 50%;
    width: 90%;
    height: 3px;
    margin-bottom: 4px;
    background: #337571;
    transform: translateX(-50%);
}

.KeyboardShortcutRow div {
    display: inline-block;
    margin-right: 10px;
}

.building-type label:last-of-type,
.location-type label:last-of-type {
    position: relative;
}

.building-type .bell-icon,
.location-type .bell-icon {
    position: absolute;
    right: -30px;
    top: 11px;
}