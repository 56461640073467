.organization-management-section .triangle-icon {
    width: 0; 
    height: 0; 
    font-size: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
}

.organization-management-section div[role="button"] {
    right: auto;
    left: 0;
}

.organization-management-section #panel1a-header div:first-of-type {
    margin: 0;
}