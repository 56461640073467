.license-form {
    width: 600px;
    position: relative;
    margin: 0 auto;
}

.license-form button {
    position: absolute;
    bottom: 0;
}

.license-form .SingleDatePicker {
    border-bottom: 1px solid #424242;
    width: 155px;
}

.license-form .SingleDatePickerInput__withBorder {
    border: none;
}

.license-form .SingleDatePicker_picker {
    position: relative;
    top: 20px !important;
}