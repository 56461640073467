.numbers-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.more-link {
    display: block;
    color: #1b536a;
    text-decoration: underline;
    cursor: pointer;
    width: 60px;
}