.alert-sub-table .item {
  display: inline-block;
}

.alert-sub-table .item + .item::before {
  display: inline-block;
  white-space: pre;
  content: ", ";
}

.alert-sub-table .more-link {
    display: block;
    color: #1b536a;
    text-decoration: underline;
    cursor: pointer;
    width: 60px;
}

.alert-sub-table .recipients-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}