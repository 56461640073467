.importing-table .rt-tbody .rt-td {
    text-align: center;
}

.importing-table .rt-tbody .rt-td div[role="button"] {
    height: auto;
}

.importing-table .rt-tbody .rt-td div[role="button"] span {
    word-break: break-word;
    white-space: normal;
}

.importing-table .rt-tbody .rt-td > div {
    padding: 9px 8px 9px 0;
    text-align: center;
}
