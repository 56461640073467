#org-level-menu ul {
    padding-top: 40px;
}

#org-level-menu ul::before {
    content: 'Go to:';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 30px;
    font-weight: bold;
} 

.logo-wrapper span {
    display: none;
    width: 200px;
    height: 80px;
    margin-bottom: 60px;
    background-size: 200px 100px !important;
    background: url('../../assets/redsky.svg') no-repeat center / cover;
}

body.collapse-nav .logo-wrapper span {
    display: block;
    margin-top: -80px;
}