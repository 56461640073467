.call-monitoring-table .rt-tr-group,
.alert-sub-recipient-table .rt-tr-group {
    max-height: 62px;
}

.combined-icon {
    position: relative;
}

.combined-icon svg:last-of-type {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -6px;
    right: -5px;
}