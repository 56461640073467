@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

/* Global */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  background-color: initial;
  color: inherit;
  font-family: 'Montserrat', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul[role="listbox"] {
  max-height: 300px;
}

/* Header */
#root .app-bar header {
  background: transparent;
  box-shadow: none;
  color: #1b536a;
}

#root .app-bar header div p:first-child {
  margin-left: 376px;
  font-size: 20px;
  font-weight: bold;
}

#root .app-bar header div button span div {
  background: #e1eff0;
  color: #1b536a;
}

#root h1 {
  color: #1b536a;
  font-size: 32px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  margin-top: -40px;
}

#root .section-wrapper {
  color: #1b536a;
  text-align: right;
  margin: -20px 10px 0 0;
  min-width: 765px;
}

#root .company-name > span,
#root .company-name button {
  font-size: 20px;
  text-transform: none;
  padding: 5px;
}

#menu-appbar ul button {
  width: 100%;
}

/* Main */
#root {
  min-width: 860px;
}

#root main {
  min-height: 100vh;
}

#root main h2,
#root main h3 {
  color: #1b536a;
  font-size: 16px;
}

/* React Table */
.ReactTable,
#root main .ReactTable {
  box-shadow: 0 0 14px -2px #00000036;
  margin-bottom: 50px;
  max-height: 70vh;
}

.ReactTable .rt-table {
  overflow: auto;
}

#root main .ReactTable button,
.ReactTable button {
  min-width: auto;
}

body.windows #root main .ReactTable.scrollable .rt-thead.-header,
body.windows .ReactTable.scrollable .rt-thead.-header {
  padding-right: 17px;
  background: #f2f2f2;
}

.ReactTable .rt-tbody,
#root main .ReactTable .rt-tbody {
  overflow-y: visible;
  overflow-x: hidden;
}

#root main .ReactTable .rt-thead .rt-th {
  white-space: unset;
  outline: none;
}

#root main .ReactTable .rt-thead .rt-resizable-header-content {
  overflow: visible;
}

#root main .ReactTable .rt-tr {
  background: #fff;
}

#root main .ReactTable .rt-tr:hover {
  background: #f2f2f2;
}

#root main .ReactTable .rt-tr button {
	color: #1b536a;
}

#root main .ReactTable .rt-th {
  background: #f2f2f2;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root main .ReactTable .rt-td,
.ReactTable .rt-td {
  white-space: normal !important;
  padding: 7px 10px !important;
  word-break: break-word;
}

#root main .ReactTable .rt-thead .rt-tr {
  background: #f2f2f2;
}

#root main .ReactTable .rt-thead.-header .rt-th:last-of-type .rt-resizer {
  display: none;
}

@media screen and (max-width: 1500px) {
  #root main .ReactTable .rt-td,
  .ReactTable .rt-td {
    font-size: 14px;
  }
}

.ReactTable .rt-noData,
#root main .ReactTable .rt-noData {
  box-shadow: 0 0 7px -3px #959494;
  color: #595959 !important;
}

.ReactTable input::-webkit-outer-spin-button,
.ReactTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ReactTable input[type=number] {
  -moz-appearance: textfield;
}

.ReactTable .-sort-asc,
.ReactTable .-sort-desc {
  box-shadow: none !important;
  position: relative;
}

.ReactTable .-sort-asc:before,
.ReactTable .-sort-desc:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.ReactTable .-sort-asc:before {
    border-bottom: 10px solid #000;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
  
.ReactTable .-sort-desc:before {
    border-top: 10px solid #000;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

/* Keyboard focus state */
.upload-button:focus-visible,
.focusable-modal:focus-visible,
.pagination-bottom button:focus-visible,
.pagination-bottom select:focus-visible,
.pagination-bottom input:focus-visible,
.rt-tr:focus-visible,
.DayPickerNavigation_button:focus-visible,
.CalendarDay:focus-visible,
.DayPickerKeyboardShortcuts_buttonReset:focus-visible,
.more-link:focus-visible {
  outline: 3px solid orangered !important;
}

.rt-tr:focus-visible,
button:focus-visible,
.upload-button:focus-visible,
.more-link:focus-visible {
  outline-offset: -3px;
}


/* Calendar Widget */
body .DateInput,
#root main .DateInput {
  width: 113px;
}

body .DateRangePickerInput_arrow_svg,
#root main .DateRangePickerInput_arrow_svg {
  width: 58px;
}

body .CalendarDay__selected,
body .CalendarDay__selected_span {
  background: #337571;
}

body .CalendarDay__selected:hover,
body .CalendarDay__selected_span:active, 
body .CalendarDay__selected_span:hover {
  background: rgb(198, 247, 242);
  border: 1px double #80e8e0;
  color: rgb(1, 109, 120);
}

body .DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #337571;
}

body .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid rgb(198, 247, 242);
}

body .DayPickerKeyboardShortcuts_show__bottomRight:hover span {
  color: rgb(1, 109, 120);
}

#template-app-bar {
  background: #1b536a;
}

body #dialog-title {
	background: #1b536a;
	padding: 24px 84px 20px 24px;
}

.tabs-bar button {
  border-right: 1px solid #ccc;
}

.tabs-bar button:last-of-type {
  border-right: none;
}

.error-prompt {
  color: rgb(230, 0, 0);
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.snackbar-popup * {
  color: #fff !important;
}

.snackbar-popup #client-snackbar svg {
  vertical-align: bottom;
}

/* Internet Explorer code */
body.ie .fixed-button {
	position: static;
}

body.ie .sidenav nav > div > svg {
  display: none;
}

body.ie .sidenav nav.active {
    width: 350px;
}

body.ie .homepage-wrapper {
  display: block;
}
