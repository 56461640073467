.upload-wrapper {
    display: flex;
    align-items: baseline;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-right: 20px;
}
  
.upload-btn-wrapper input[type=file] {
    display: none;
}

.upload-button {
    background: #1b536a;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    line-height: 1.75;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display: inline-block;
    color: #fff;
    cursor: pointer;
}