.show-message-alert {
    position: absolute;
    color: red;
    top: 50px;
    left: 0;
    width: 100%;
    background: #f7bcbc;
    padding: 5px;
    text-align: center;
    box-sizing: border-box;
    z-index: 99;
}

.modal > div > div {
    width: 60%;
    max-width: 740px;
    overflow: visible;
}

.modal > div > div > div:last-of-type {
    padding-top: 15px;
}

.modal.generate-reports > div > div {
    max-width: 820px;
}

.modal.schedule-reports > div > div {
    max-width: 700px;
}

.modal #dialog-title {
    display: block;
}

.modal > div > div > div:last-child {
    background: #fafafa;
}

.modal > div > div div:last-child:focus {
    outline: none;
}

.modal > div > div div:last-child::-webkit-scrollbar {
    -webkit-appearance: none;
}
  
.modal > div > div div:last-child::-webkit-scrollbar:vertical {
    width: 11px;
}

.modal > div > div div:last-child::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}

.modal #closeBtn {
    margin-top: -18px;
}
/* 
.modal form > div {
    margin: 0;
} */