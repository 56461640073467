.template-form {
    padding: 24px;
    overflow-y: scroll;
}

.template-form .canvas * {
    background-color: #fff;
    color: #000;
}

.template-name {
    display: flex;
    align-items: center;
}

.template-name > div {
    width: 300px;
}

.template-name > div:last-of-type {
    margin: 5px 0 0 100px;
}

.template-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.template-wrapper .template-tags {
    width: 300px;
}

.template-wrapper .template-preview {
    width: 65%;
}

.template-tags {
    margin-right: 10%;
}

.template-tags ul {
    border: 1px solid #000;
    height: 500px;
    list-style: none;
    padding: 0;
    overflow-y: scroll;
}

.template-tags .row {
    display: flex;
    align-items: center;
}

.template-tags li {
    border: 1px solid #000;
    padding: 7px 10px;
    margin: 10px;
    width: 67%;
    position: relative;
}

.template-tags li.clickable {
    background: url('./assets/add_circle.svg') no-repeat 8px center #fff;
}

.template-tags .arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
}

.template-preview,
.template-preview .canvas,
.template-preview .subject {
    position: relative;
    outline: none;
}

.template-preview .canvas {
    border: 1px solid #000;
    margin-top: 30px;
    height: 407px;
    padding: 10px;
    overflow-y: scroll;
    line-height: 2.9;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.template-preview .canvas:empty:not(:focus):before,
.template-preview .subject:empty:not(:focus):before {
    content:attr(data-text);
    color: grey;
    position: absolute;
    left: 10px;
}

.template-preview .subject {
    border-bottom: 1px solid #000;
    padding: 5px 15px;
    height: 42px;
    overflow-y: scroll;
    line-height: 2.9;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.template-switch {
    position: absolute !important;
    top: 0;
    right: 0;
}

.template-preview .tag {
    display: inline-block;
    position: relative;
    border: 1px solid #000;
    padding: 0px 20px 8px 10px;
    line-height: 1;
    user-select: none;
    -webkit-user-select: none;
}

.template-preview .tag[data-type=sampleContent] {
    border: none;
    padding: 0;
}

.template-preview .tag:after {
    content: "+";
    position: relative;
    display: inline-block;
    right: -14px;
    top: 4px;
    font-size: 30px;
    transform: rotate(45deg);
}

.template-preview .tag[data-type=sampleContent]:after {
    content: "";
}

.help-icon {
    position: relative;
    display: inline-block;
    padding: 5px;
}

.help-icon::after {
    display: none;
    content: "";
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 34px;
    margin: auto;
    width: 473px;
    height: 335px;
    background: url(./assets/template.gif) no-repeat center / cover;
    z-index: 1;
    border: 1px solid #000;
}

.help-icon.secondary::after {
    width: 600px;
    height: 477px;
    background: url(./assets/template2.gif) no-repeat center;
}

.help-icon:hover::after {
    display: block;
}

.fullscreen-modal > div > div {
    max-width: 1200px;
    width:1100px;
}

.fullscreen-modal > div > div::-webkit-scrollbar {
    -webkit-appearance: none;
}
  
.fullscreen-modal > div > div::-webkit-scrollbar:vertical {
    width: 11px;
}

.fullscreen-modal > div > div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}


