.locations-app-bar .tabs {
    text-transform: none;
    position: relative;
}

.locations-app-bar button {
    min-height: 48px;
    width: 50%;
}

.locations-app-bar button > span {
    margin-bottom: 10px;
}

#dataSync-info,
#cer-info,
#e911-info {
    position: absolute;
    top: 10px;
    right: 10px;
}
