.stats-table-wrapper {
    position: relative;
}

.stats-table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 14px -2px #00000036;
    margin: 0 0 50px;
}
  
.stats-table td, .stats-table th {
    border: 1px solid rgba(0,0,0,0.05);
    text-align: center;
    padding: 8px;
}

.stats-table th {
    background: #f2f2f2;
    padding: 20px;
    font-weight: normal;
    cursor: default;
}

.stats-table-wrapper div[role="progressbar"] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.stats-table thead > tr td:first-of-type,
.stats-table tbody > tr td:first-of-type {
    text-align: left;
}

.stats-table thead > tr > td:nth-of-type(even) table tbody tr td,
.stats-table tbody > tr > td:nth-of-type(even) table tbody tr td {
    text-align: center;
}

.stats-table tbody > tr:hover {
    background: #f2f2f2;
}

.stats-table table tbody > tr:hover {
    background: initial;
}

.stats-table table td,
.stats-table .wrapper-td {
    padding: 0;
}

.stats-table .wrapper-td table,
.stats-table table tr {
    width: 100%;
    display: table;
}

.stats-table table tr td {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.stats-table table tr:last-of-type td {
    border: none;
}