.search-input {
    width: 200px;
    height: 35px;
    box-sizing: border-box;
    background: url(../../assets/search_icon.svg) no-repeat right 8px center / auto 70%;
    padding: 0 40px 0 10px;
    margin-top: 0px;
    font-size: 16px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline: none;
}