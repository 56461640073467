.sidenav {
    position: relative;
    min-height: 100vh;
    background: url(../../assets/buildings.png) no-repeat  0 bottom / contain #6ddcf1;
    margin: -74px 75px 0 0;
    max-width: 320px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
}

body.collapse-nav .sidenav {
    min-width: auto;
    width: 55px;
    margin: -74px 25px 0 0;
    z-index: 9999;
}

body.collapse-nav.overlay-nav .sidenav {
    min-width: 320px;
}

body.collapse-nav.overlay-nav #main-content {
    margin-left: -265px;
}

.sidenav .menu-icon {
    position: relative;
    padding-left: 1px;
    margin: -20px 0 20px 0;
    min-width: auto;
    width: 55px;
    border-radius: none;
}

.sidenav .menu-icon::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #155F74;
    position: absolute;
    left: 0;
    bottom: 0;
}

body.five9 .sidenav .menu-icon::after {
    background: #fff;
}

body.collapse-nav.overlay-nav .sidenav .menu-icon::after {
    width: 320px;
}

.sidenav > div {
    background: transparent;
}

.sidenav > div > div > div,
.sidenav > div > div {
    padding: 0;
}

.sidenav span {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    color: #155F74;
    font-weight: bold;
}

body.five9 .sidenav span {
    color: #fff;
}

.sidenav svg {
    fill: #155F74;
}

body.five9 .sidenav svg {
    fill: #fff;
}

.sidenav .nav-link-text span {
    display: block;
}

body.collapse-nav .sidenav .nav-link-text span {
    font-size: 0;
}

body.collapse-nav.overlay-nav .sidenav .nav-link-text span {
    font-size: 14px;
}

.sidenav .nested div:nth-of-type(2) {
    padding-left: 0;
}

.sidenav .sub-icon {
    margin-left: 8px;
    width: 20px;
}

.sidenav .sub-icon.notification-icon {
    position: absolute;
    top: 9px;
    left: 13px; 
    transform: scale(0.6);
}

body.collapse-nav .sidenav .sub-icon.notification-icon {
    top: 5px;
    left: 24px;
}

.sidenav #notification-integrations-nav-item div:nth-of-type(2) {
    margin-right: 0;
}

.sidenav .nested {
    padding-right: 0;
    padding-left: 0;
}

.sidenav .details {
    padding: 1px 0 0 25px;
}

.sidenav .summary > div {
    margin: 0;
}

.sidenav .top-wrapper {
    margin-bottom: 20px;
}

.sidenav.att-skin .top-wrapper {
    background: #fff;
    margin-top: 74px;
}

body.collapse-nav .sidenav .top-wrapper {
    margin-bottom: 100px;
}

.sidenav .logo {
    display: block;
    width: 200px;
    height: 80px;
    margin: 100px auto 0px;
    background-size: 200px 100px !important;
    background: url('../../assets/redsky.svg') no-repeat center / cover;
}

body.collapse-nav .sidenav .top-wrapper .logo,
body.collapse-nav .sidenav.att-skin .top-wrapper .logo {
    display: none;
}

.sidenav .mid-wrapper > div {
    background: transparent;
}

body.collapse-nav .sidenav .mid-wrapper > a {
    padding: 6px 0 6px 13px;
}

body.collapse-nav .sidenav .mid-wrapper li {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: -1px;
}

.sidenav .mid-wrapper div[role="button"]:focus,
.sidenav .mid-wrapper div[role="button"]:hover {
    background: rgba(0, 0, 0, 0.08);
}

body.collapse-nav.overlay-nav .sidenav .mid-wrapper li {
    padding-left: 14px;
    margin-left: 0;
}

body.collapse-nav.overlay-nav .sidenav .mid-wrapper li .nav-link-text {
    padding-left: 14px;

}

body.collapse-nav .sidenav .mid-wrapper li div:first-of-type {
    margin-right: 0;
}

body.collapse-nav .sidenav .mid-wrapper > div > div {
    min-height: auto;
}

body.collapse-nav .sidenav .mid-wrapper > div > div > div > div {
    padding: 6px 0 0 4px;
}

body.collapse-nav .sidenav .mid-wrapper > div > div > div > div > div {
    padding: 6px 0;
    margin: 0 0 0 10px;
}

body.collapse-nav.overlay-nav .sidenav .mid-wrapper > div > div > div > div > div {
    margin: 0 5px 0 10px;
}

body.collapse-nav .sidenav .mid-wrapper > div > div > div > div > div a {
    padding: 7px 0 3px 11px;
    width: 40px;
    box-sizing: border-box;
}

body.collapse-nav.overlay-nav .sidenav .mid-wrapper > div > div > div > div > div a {
    width: auto;
}

body.collapse-nav .sidenav .mid-wrapper > div > div > div > div > div a svg {
    margin: 0;
}

body.collapse-nav.overlay-nav .nav-link-text {
    padding-left: 0;
}

.sidenav .bottom-wrapper {
    margin-top: auto;
}

.sidenav h3 {
    text-align: center;
    color: #1b536a;
    margin-bottom: 0;
}

body.collapse-nav .sidenav .bottom-wrapper img {
    display: none;
}

body.collapse-nav.overlay-nav .sidenav .bottom-wrapper img {
    display: block;
}

.sidenav img {
    display: block;
    width: 120px;
    margin: 0 auto;
}