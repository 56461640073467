.reseller-icon,
.customer-icon {
    width: 100%;
    height: auto;
}


.reseller-icon > *,
.customer-icon > * {
    fill: #1b536a;
}

.reseller-icon.five9 > *,
.customer-icon.five9 > *{
    fill: #000;
}