.homepage-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.homepage-wrapper a {
    color: inherit;
    text-decoration: none;
}

.homepage-wrapper > div {
    width: calc(50% - 30px);
    margin: 0 15px 50px 0;
}

.homepage-wrapper.full-width > div {
    width: calc(100% - 30px);
}

@media screen and (max-width: 1200px) {
    #root .homepage-wrapper > div {
        width: calc(100% - 30px);
        min-width: 740px;
    }
}