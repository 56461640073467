.scheduled-report-table .rt-table {
    overflow-x: hidden;
}

.scheduled-report-table .rt-tbody .rt-tr .rt-td:nth-of-type(1),
.scheduled-report-table .rt-thead .rt-tr .rt-th:nth-of-type(1) {
    width: 15% !important;
}

.scheduled-report-table .rt-tbody .rt-tr .rt-td:nth-of-type(3),
.scheduled-report-table .rt-thead .rt-tr .rt-th:nth-of-type(3) {
    width: 16% !important;
}

.scheduled-report-table .rt-tbody .rt-tr .rt-td:nth-of-type(4),
.scheduled-report-table .rt-thead .rt-tr .rt-th:nth-of-type(4) {
    width: 25% !important;

}

div[role="tooltip"] > div {
    font-size: 12px;
}