.company-summary-wrapper {
    background: #e1eff0;
    border-radius: 5px;
}

.company-summary-wrapper ul {
    padding: 20px 6px;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.company-summary-wrapper li {
    display: flex;
    align-items: center;
    color: #1b536a;
}

.company-summary-wrapper span:last-of-type {
    display: block;
    color: #1b536a;
    font-size: 25px;
    text-align: center;
}

.company-summary-wrapper .vertical-border {
    height: 44px;
    width: 2px;
    background: #e0e0e0;
}