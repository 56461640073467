.subscription-form .button {
    margin: 23px 0 0 15px;
    float: left;
    background: #1b536a;
}

.subscription-form .section-wrapper {
    border: 1px solid #9e9e9e;
    margin: 20px 0;
    padding: 0 20px;
}

.subscription-form #saveBtn {
    margin-bottom: 20px;
    background: #1b536a;
}

#picky {
    margin: 10px 0 8px;
}

#picky button {
    border: none;
    border-bottom: 1px solid #949494;
    width: 100%;
    background: transparent;
    text-align: left;
}

#picky button:hover {
    border-bottom: 2px solid #000;
}

#picky button:focus {
    outline: none;
    border-bottom: 2px solid #4050b5;
}

#picky button:focus span,
#picky button:hover span {
    position: relative;
    top: 1px;
}

.picky__input::after {
    border-top: 5px solid #747474;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    right: 7px;
}

#picky .picky__dropdown {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    border-radius: 4px;
}

#picky .picky__dropdown .option:focus {
    outline: none !important;
}

.picky__dropdown .option, 
.picky__dropdown li {
    border: none;
    padding-top: 11px;
    padding-bottom: 11px;
}

.picky__placeholder {
    padding: 10px;
    display: block;
}

.picky__filter {
    margin: 10px 10px 0;
}

#picky__button__button {
    padding: 0;
    position: relative;
}

#picky__button__button::after {
    content: '';
    position: absolute;
    right: 7px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #757575;
}

#picky__button__button > span {
    font-size: 16px;
    padding: 0 20px 5px 0;
    display: inline-block;
}


.picky__dropdown .option {
    cursor: pointer;
}