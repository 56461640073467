.snackbar-popup {
    color: #fff;
}

.codes-table .hidden-key {
    font-size: 25px;
    width: 100%;
    background: transparent;
    border: none;
    pointer-events: none;
}

.codes-table.visible .hidden-key {
    font-size: 14px;
    line-height: 2.15;
}

.codes-table .rt-tbody .rt-td:nth-of-type(1),
.codes-table .rt-tbody .rt-td:nth-of-type(2) {
    margin-top: 10px;
}