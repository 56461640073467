.search-input {
    width: 200px;
    height: 35px;
    box-sizing: border-box;
    background: url(../../assets/search_icon.svg) no-repeat right 8px center / auto 70%;
    padding: 0 40px 0 10px;
    margin-top: 0px;
    font-size: 16px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline: none;
}

.org-table .rt-td button {
    text-align: left;
}

.org-table .rt-td:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-provider-icon {
    width: 100%;
    height: auto;
}

.service-provider-icon > * {
    fill: #1b536a;
}

.service-provider-icon.five9 > * {
    fill: #000;
}