.details-list {
    padding: 0;
    margin: 0;
    width: 107%;
    position: relative;
    left: -24px;
}

.details-list li {
    list-style: none;
    padding: 20px 0 20px 20px;
}

.details-list li:nth-child(odd) {
    background: #eeeeee;
}