.error-page {
    background: #e2e8e5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.error-page .section {
    max-width: 655px;
    margin: 0 auto;
    color: #707070 !important;
    font-size: 24px;
}

.error-page .section img {
    display: block;
    width: 500px;
    margin: 0 auto;
}

.error-page .section h1 {
    text-align: center;
    font-weight: bold !important;
    font-size: 48px !important;
    margin: 7px auto !important;
    color: #707070 !important;
}

.error-page .section span {
    text-decoration: underline;
    cursor: pointer;
}