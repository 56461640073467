.single-select .css-bg1rzq-control,
.multi-select .css-bg1rzq-control,
.single-select .css-1x0qmr5-control,
.multi-select .css-1x0qmr5-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    z-index: 99;
    box-shadow: none;
}

.single-select .css-bg1rzq-control:hover,
.multi-select .css-bg1rzq-control:hover
.single-select .css-1x0qmr5-control:hover,
.multi-select .css-1x0qmr5-control:hover {
    border-bottom: 2px solid #000;
}

.css-bgvzuu-indicatorSeparator {
    display: none;
}

.single-select.error .css-bg1rzq-control,
.multi-select.error .css-bg1rzq-control,
.single-select.error .css-1x0qmr5-control,
.multi-select.error .css-1x0qmr5-control {
    border-color: red;
    border-width: 2px;
}

.css-kj6f9i-menu {
    z-index: 9999 !important;
}

.single-select .css-151xaom-placeholder,
.multi-select .css-151xaom-placeholder {
    color: rgb(97, 95, 87);
}