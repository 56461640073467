#login-page .logo {
    width: 200px;
    height: 80px;
    background-size: 200px 100px !important;
    background: url('../../assets/redsky.svg') no-repeat center / cover;
    position: absolute;
    top: 30px;
    right: 60px;
}

