.DateRangePicker {
    border: 1px solid #424242;
    width: 307px;
    margin-bottom: 20px;
}


.DateRangePicker *:focus-visible {
    outline: none;
}

.DateRangePicker_picker {
    z-index: 99;
}

div.event-type,
div.role-type {
    min-width: 200px;
    max-width: 300px;
    margin: -11px 15px 10px;
}

.chip-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.event-type-chip,
.role-type-chip {
    display: flex;
    flex-wrap: wrap;
    margin: 2px;
}

.events .description-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.events .description-wrapper .more-link {
    display: block;
    color: #1b536a;
    text-decoration: underline;
    cursor: pointer;
    width: 60px;
}