#logout-modal-title {
    padding: 24px 84px 20px 24px; 
}

#logout-modal-title * {
    color: #fff;
    font-size: 1rem;

}

.logout-modal-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 25px 0 15px;
}

.logout-modal p,
.logout-confirmation-modal p {
    padding: 0 15px;
}

.logout-modal button:first-of-type {
    background: #a4a4a4 !important;
}

.logout-modal button:first-of-type:hover {
    background: #c3c3c3 !important;
}

.logout-modal strong {
    display: inline-block;
    width: 36px;
    padding-left: 2px;
    margin: 0 -2px 0 -3px;
}