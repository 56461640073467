.device-user-form .border {
    background: #1b536a;
    height: 2px;
    width: 7vw; 
    max-width: 90px;
    margin: 0; 
    border: none;
}

@media screen and (max-width: 1144px) {
    .device-user-form .border {
        width: 5vw;
    }
}

@media screen and (max-width: 1052px) {
    .device-user-form .border {
        width: 4vw;
    }
}